<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addGlobalSettingsModal' hide-footer>
        <addGlobalSettings @closeAddGlobalSettings='toggleAddGlobalSettings()' @addGlobalSettingsSuccess='addGlobalSettingsSuccess()'></addGlobalSettings>
      </b-modal>
      <b-modal ref='editGlobalSettingsModal' hide-footer>
        <editGlobalSettings
          :editingGlobalSettings='currentGlobalSettings'
          @closeEditGlobalSettings='toggleEditGlobalSettings()'
          @editGlobalSettingsSuccess='editGlobalSettingsSuccess()'
        ></editGlobalSettings>
      </b-modal>
      <div class='row'>
        <h2>GlobalSettings</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddGlobalSettings()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addGlobalSettings from '../../components/addGlobalSettings';
import editGlobalSettings from '../../components/editGlobalSettings';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'SettingName',
                field: 'SettingName',
                sort: 'asc',
              },
              {
                label: 'SettingValue',
                field: 'SettingValue',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addGlobalSettingsOpen: false,
      editGlobalSettingsOpen: false,
      currentGlobalSettings: {}
    };
  },
  components: {
    addGlobalSettings,
    editGlobalSettings,
    Datatable,
  },
  created: function() {
    this.getGlobalSettings();
    this.addGlobalSettingsOpen = false;
    this.editGlobalSettingsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getGlobalSettings() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getGlobalSettings', payload);
    },
    editItem(GlobalSettings) {
      this.toggleEditGlobalSettings();
      this.currentGlobalSettings = GlobalSettings;
    },
    toggleAddGlobalSettings() {
      if(this.addGlobalSettingsOpen)
      {
        this.$refs.addGlobalSettingsModal.hide()
      }
      else{
        this.$refs.addGlobalSettingsModal.show()
      }
      this.addGlobalSettingsOpen = !this.addGlobalSettingsOpen;
    },
    addGlobalSettingsSuccess() {
      this.toggleAddGlobalSettings();
      miniToastr['success']('GlobalSettings Added', 'Success', 1000, null);
      this.getGlobalSettings();
    },
    toggleEditGlobalSettings() {
      if(this.editGlobalSettingsOpen)
      {
        this.$refs.editGlobalSettingsModal.hide()
      }
      else{
        this.$refs.editGlobalSettingsModal.show()
      }
      this.editGlobalSettingsOpen = !this.editGlobalSettingsOpen;
    },
    editGlobalSettingsSuccess() {
      this.toggleEditGlobalSettings();
      miniToastr['success']('GlobalSettings Edited', 'Success', 1000, null);
      this.getGlobalSettings();
    }
  }
};
</script>
<style scoped>
.GlobalSettingsThumbnail {
  height: 50px;
}
</style>
